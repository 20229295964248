<template>
  <div :custom-messages="messages" :name="label" slim>
    <div class="Checkbox" :class="{ 'is-secondary': variant === 'secondary' }">
      <div :class="{ 'has-errors': !!errorMessage }" class="Checkbox__Control">
        <label v-if="label || $slots.default" class="w-full" :for="id">
          <slot>
            {{ label }}
          </slot>
        </label>

        <input
          :id="id"
          v-model="valueField"
          class="absolute hidden"
          :checked="isChecked"
          :name="name"
          type="checkbox"
          aria-hidden="true"
          tabindex="-1"
          @change="toggle"
        />
        <span
          class="Checkbox__Input"
          :class="{
            '': align === 'normal',
            '-order-1': align === 'reversed',
          }"
        >
          <slot name="checkbox">
            <button
              role="switch"
              :aria-checked="isChecked ? 'true' : 'false'"
              :title="label"
              class="Checkbox__Btn flex-shrink-0 border flex items-center justify-center"
              :class="{ 'is-active': isChecked }"
              type="button"
              @click="toggle"
            >
              <svg-icon-check :class="{ invisible: !isChecked }" class="stroke-current" />
            </button>
          </slot>
        </span>
      </div>
      <span class="text-red-700 text-xs absolute">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash-es';

const props = defineProps({
  id: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    default: '',
    required: true,
  },
  messages: {
    type: Object,
    default: null,
  },
  // Customizes the value set on the model when the checkbox is checked
  checkedValue: {
    type: null,
    default: true,
  },
  // Customizes the value set on the model when the checkbox is unchecked.
  // Has no effect when the input is bound to an array.
  uncheckedValue: {
    type: null,
    default: false,
  },
  // the attribute name of the current item being changed (if we're passing an object of values)
  propName: {
    type: String,
    default: undefined,
  },
  variant: {
    type: String,
    default: '',
  },
  value: {
    type: null,
    default: undefined,
  },

  // UI

  align: {
    type: String,
    /** * @type {string} */
    validator: (v: string): boolean => ['reversed', 'normal'].includes(v),
    default: 'reversed',
  },
});

const nameRef = toRef(props, 'name');

const { errorMessage, value: valueField, setValue } = useField(nameRef.value);

setValue(resolveValueField());

const emit = defineEmits(['input']);
/**
 * handles checking or unchecking checkbox
 * returns true to check and false to uncheck
 */
const isChecked = computed(() => {
  const valueToCheck = toRaw(valueField.value);

  // if we have an array of selected values ex: categories filters
  if (Array.isArray(valueToCheck)) {
    // return true if the selected values' array contains the checkbox's checkedValue
    return !!valueToCheck.find(item => isEqual(toRaw(item), toRaw(props.checkedValue)));
  }
  // if we have an object of all values ex: more filters && the value is not null
  if (typeof valueToCheck === 'object' && valueToCheck && props.propName) {
    // compare the current item's value in the object with the checkbox's checkedValue
    return (valueToCheck as unknown as Record<string, string>)[props.propName || ''] === props.checkedValue;
  }
  // otherwise we're passing a single value
  return valueToCheck === toRaw(props.checkedValue);
});

/**
 * handles toggling the checkbox's state
 */
function toggle() {
  const valueToCheck = toRaw(valueField.value);
  const checkedValue = toRaw(props.checkedValue);

  // if have an object of values
  if (typeof valueToCheck === 'object' && valueToCheck && !Array.isArray(valueToCheck)) {
    // then shallow clone the object to handle mutability
    // and toggle the value of the current selected item
    // and emit the event with the new object
    setValue({ ...valueToCheck, [props.propName || '']: isChecked.value ? props.uncheckedValue : props.checkedValue });

    return emit('input', {
      ...valueToCheck,
      [props.propName || '']: isChecked.value ? props.uncheckedValue : props.checkedValue,
    });
  }
  // if we have a single value and current item is checked
  if (isChecked.value && !Array.isArray(valueToCheck)) {
    setValue(props.uncheckedValue);

    return emit('input', props.uncheckedValue);
  }
  // if we have an array of values and checkbox is currently checked
  if (Array.isArray(valueToCheck) && isChecked.value) {
    // filter out the current value from the array

    // and emit the input event with the filtered array
    setValue(valueToCheck.filter(val => !isEqual(val, checkedValue)));
    return emit(
      'input',
      valueToCheck.filter(val => !isEqual(val, checkedValue))
    );
  }

  const newVal = Array.isArray(valueToCheck) ? [...valueToCheck, checkedValue] : checkedValue;
  setValue(newVal);
  emit('input', newVal);
}

function resolveValueField(newValue = props.value) {
  const propsValue = toRaw(newValue);
  if (Array.isArray(propsValue)) {
    return props.propName
      ? propsValue.find(option => option[props.propName || ''] === props.checkedValue[props.propName || ''])
      : propsValue;
  }

  return propsValue;
}

watch(
  () => props.value,
  newValue => {
    setValue(resolveValueField(newValue));
  }
);
</script>

<style lang="postcss" scoped>
/* purgecss start ignore */
.Checkbox {
  @apply relative text-primary-1-100;

  &__Input {
    @apply w-5 h-5;
    transition: background-color 0.2s ease-in-out;
  }

  &__Control {
    @apply flex items-center;
    input {
      @apply opacity-0;
    }
    label {
      margin-left: 10px;
    }
  }

  &__Btn {
    @apply w-5 h-5 outline-none border-primary-1-100;

    &.is-active {
      @apply bg-white;
    }

    svg {
      @apply text-primary-1-100;
    }
  }

  &.is-secondary {
    @apply text-secondary-700;

    .Checkbox__Btn {
      @apply border-primary-1-100;
      &.is-active {
        @apply bg-white border-primary-1-100;
      }

      svg {
        @apply text-black;
      }
    }
  }
}
.-order-1 {
  order: -1;
}
/* purgecss end ignore */
</style>
